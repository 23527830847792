.warrior-loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 200px;
  }

  .warrior-book {
    width: 60px;
    height: 80px;
    position: relative;
    perspective: 1000px;
    transform-style: preserve-3d;
  }

  .book-page {
    position: absolute;
    width: 100%;
    height: 100%;
    border: 2px solid #3b82f6;
    border-radius: 0 10px 10px 0;
    background-color: #fff;
    transform-origin: left center;
    animation: flipPage 1.5s infinite ease-in-out;
  }

  .book-page:nth-child(1) {
    animation-delay: 0s;
  }

  .book-page:nth-child(2) {
    animation-delay: 0.5s;
  }

  .book-page:nth-child(3) {
    animation-delay: 1s;
  }

  @keyframes flipPage {
    0%, 100% { transform: rotateY(0deg); }
    50% { transform: rotateY(-180deg); }
  }

  .loading-text {
    margin-top: 20px;
    font-size: 18px;
    color: #3b82f6;
    font-weight: bold;
    text-align: center;
  }