@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.story-content {
  font-family: 'Poppins', sans-serif;
  line-height: 1.8;
  color: #333;
}

.story-content h1 {
  font-size: 2.5rem;
  color: #2563eb;
  margin-bottom: 1.5rem;
}

.story-content p {
  margin-bottom: 1.5rem;
  font-size: 1.125rem;
}

.story-content img {
  border-radius: 1rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
}

.prose {
  max-width: none;
}

.prose h1 {
  color: #2563eb;
  font-weight: 600;
}

.prose a {
  color: #2563eb;
  text-decoration: underline;
}

.prose a:hover {
  color: #1e40af;
}

.prose ul, .prose ol {
  padding-left: 1.5rem;
}

.prose li {
  margin-bottom: 0.5rem;
}